/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import Modal from "react-modal";
import Times from "../../static/img/times-solid.svg";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Form = () => {
  const [state, setState] = React.useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "0px",
      padding: "0rem",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => openModal())
      .catch((error) => alert(error));
  };

  return (
    <React.Fragment>
      <form
        sx={{ display: "flex", flexDirection: "column" }}
        name="Kontakt Formular v4"
        method="post"
        action="/#Kontakt"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="Kontakt Formular v4" />
        <span hidden>
          <label>
            <span hidden>Bitte nicht ausfüllen (Botfeld): </span>
            <input name="bot-field" onChange={handleChange} />
          </label>
        </span>
        <div>
          <label>
            <span hidden>
              Ihr Name:
              <br />
            </span>
            <input
              required
              sx={{
                fontFamily: "Lato",
                width: "100%",
                borderColor: "one",
                borderStyle: "solid",
                borderWidth: [4, 7, 7],
                borderRadius: "0px",
                fontSize: [1, 5, 5],
                padding: 1,
                mb: 3,
                "::placeholder": {
                  fontFamily: "Lato",
                  color: "background2",
                  fontSize: [1, 5, 5],
                },
              }}
              type="text"
              name="Name"
              placeholder="Ihr Name"
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            <span hidden>
              Ihre E-Mail Adresse:
              <br />
            </span>
            <input
              required
              sx={{
                fontFamily: "Lato",
                width: "100%",
                borderColor: "one",
                borderStyle: "solid",
                borderWidth: [4, 7, 7],
                borderRadius: "0px",
                fontSize: [1, 5, 5],
                padding: 1,
                mb: 3,
                "::placeholder": {
                  fontFamily: "Lato",
                  color: "background2",
                  fontSize: [1, 5, 5],
                },
              }}
              type="email"
              name="E-Mail"
              placeholder="Ihre E-Mail Adresse"
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            <span hidden>
              Ihre Telefonnummer:
              <br />
            </span>
            <input
              required
              sx={{
                fontFamily: "Lato",
                width: "100%",
                borderColor: "one",
                borderStyle: "solid",
                borderWidth: [4, 7, 7],
                borderRadius: "0px",
                fontSize: [1, 5, 5],
                padding: 1,
                mb: 3,
                "::placeholder": {
                  fontFamily: "Lato",
                  color: "background2",
                  fontSize: [1, 5, 5],
                },
              }}
              type="tel"
              name="Telefon"
              placeholder="Ihre Telefonnummer"
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            <span hidden>
              Ihre Telefonnummer:
              <br />
            </span>
            <textarea
              required
              sx={{
                fontFamily: "Lato",
                width: "100%",
                height: "8rem",
                borderColor: "one",
                borderStyle: "solid",
                borderWidth: [4, 7, 7],
                borderRadius: "0px",
                fontSize: [1, 5, 5],
                padding: 1,
                mb: 3,
                "::placeholder": {
                  fontFamily: "Lato",
                  color: "background2",
                  fontSize: [1, 5, 5],
                },
              }}
              type="text"
              name="Nachricht"
              placeholder="Ihre Nachricht"
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <button
            sx={{ variant: "buttons.cta", bg: "one", width: "100%" }}
            type="submit"
          >
            Absenden
          </button>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={customStyles}
        contentLabel="Example Modal"
        transparent={true}
      >
        <div
          sx={{
            display: "flex",
            p: "0.5rem",
            position: "relative",
            maxWidth: ["300px", "100vw", "100vw"],
          }}
        >
          <div
            sx={{ textAlign: "center", w: "24rem", py: "1.5rem", px: "2.5rem" }}
          >
            <div
              sx={{
                color: "background",
                mb: ["0.9375rem", "1.875rem", "1.875rem"],
                fontWeight: 700,
                fontSize: ["1.5625rem", "2.5rem", "2.5rem"],
                lineHeight: ["1.875rem", "3rem", "3rem"],
              }}
            >
              Vielen Dank!
            </div>
            <div
              sx={{
                fontSize: ["1rem", "1.25", "1.25"],
                lineHeight: ["1.2rem", "1.35rem", "1.35rem"],
                mb: ["1.2rem", "2.4rem", "2.4rem"],
                color: "background",
                fontWeight: 500,
              }}
            >
              Wir werden uns baldmöglichst mit Ihnen in Verbindung setzen
            </div>
            <button
              sx={{
                border: "none",
                fontSize: ["1.25rem", "1.5625rem", "1.5625rem"],
                lineHeight: ["1.5rem", "1.875rem", "1.875rem"],
                color: "text",
                bg: "one",
                px: "2rem",
                py: "1rem",
              }}
              onClick={closeModal}
            >
              Zurück
            </button>
          </div>
          <button
            sx={{
              border: "none",
              bg: "#ffffff",
              width: "1rem",
              height: "1rem",
              position: "absolute",
              top: "0.5rem",
              right: "0.75rem",
            }}
            onClick={closeModal}
          >
            <Times />
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Form;
